import React from "react";

import styles from "./NextPageCard.module.css";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

function NextPageCard(props) {
  return (
    <div className={styles.Card} onClick={props.onClick}>
      <ExpandMoreIcon />
    </div>
  );
}

export default NextPageCard;
