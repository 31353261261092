import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withAuthorization } from "../components/Session";

import AppHeader from "../components/AppHeader/AppHeader";
import ContentBody from "../components/ContentBody/ContentBody";
import QuoteCard from "../components/QuoteCard/QuoteCard";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import NextPageCard from "../components/NextPageCard/NextPageCard";
import SortingCard from "../components/SortingCard/SortingCard";

const SORT_MODES = {
  new: { field: "date", sort: "desc" },
  top: { field: "score", sort: "desc" }
};

class PageBookmarks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quotes: [],
      currentNewestDoc: undefined,
      isLoadingNextPage: true,
      sorting: "new"
    };

    this.goBack = this.goBack.bind(this);
    this.nextPage = this.nextPage.bind(this);
  }

  nextPage() {
    this.setState({ isLoadingNextPage: true });

    const { currentNewestDoc, sorting } = this.state;
    if (!currentNewestDoc) return;

    const { firebase } = this.props;
    const db = firebase.firestore;

    const sortMode = SORT_MODES[sorting];

    var quotesRef = db.collection("Quotes");

    const userUid = firebase.auth.getUid();

    quotesRef
      .where("bookmarkedBy", "array-contains", userUid)
      .orderBy(sortMode.field, sortMode.sort)
      .limit(25)
      .startAfter(currentNewestDoc)
      .get()
      .then(
        snapshot => {
          var { quotes } = this.state;

          var lastDoc = undefined;

          snapshot.forEach(doc => {
            quotes.push({ id: doc.id, ...doc.data() });
            lastDoc = doc;
          });

          this.setState({
            isLoadingNextPage: lastDoc === undefined,
            quotes,
            currentNewestDoc: lastDoc
          });
        },
        error => {
          alert(error);
          this.setState({ isLoadingNextPage: false });
        }
      );
  }

  componentDidMount() {
    const { sorting } = this.state;
    const { firebase } = this.props;
    const db = firebase.firestore;

    var quotesRef = db.collection("Quotes");

    const sortMode = SORT_MODES[sorting];

    //const userUid = firebase.auth.getUid();

    firebase.auth.onAuthStateChanged(user => {
      quotesRef
        .where("bookmarkedBy", "array-contains", user.uid)
        .orderBy(sortMode.field, sortMode.sort)
        .limit(25)
        .get()
        .then(
          snapshot => {
            var { quotes } = this.state;

            quotes = [];

            var lastDoc = undefined;

            snapshot.forEach(doc => {
              quotes.push({ id: doc.id, ...doc.data() });
              lastDoc = doc;
            });

            this.setState({
              isLoadingNextPage: false,
              quotes,
              currentNewestDoc: lastDoc
            });
          },
          error => {
            alert(error);
            this.setState({ isLoadingNextPage: false });
          }
        );
    });
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    const { quotes, isLoadingNextPage, sorting } = this.state;

    const items = quotes.map(q => (
      <QuoteCard key={q.id} quote={q} firebase={this.props.firebase} />
    ));

    return (
      <div>
        <AppHeader
          left={<ArrowBackIcon onClick={this.goBack} />}
          title="Gespeichert"
        />
        <ContentBody offset={40}>
          <SortingCard
            sorting={sorting}
            setSort={sort => {
              this.setState({
                sorting: sort,
                quotes: [],
                currentNewestDoc: undefined,
                isLoadingNextPage: true
              });

              setTimeout(() => this.componentDidMount(), 100);
            }}
          />
          {items}
          {!isLoadingNextPage && <NextPageCard onClick={this.nextPage} />}
        </ContentBody>
      </div>
    );
  }
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(withRouter(PageBookmarks));
