import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import "./App.css";
import PageLogin from "./pages/PageLogin";
import PageHome from "./pages/PageHome";
import PageNewQuote from "./pages/PageNewQuote";
import PageProfile from "./pages/PageProfile";
import PageBookmarks from "./pages/PageBookmarks";

class App extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={PageHome} />
        <Route exact path="/new" component={PageNewQuote} />
        <Route exact path="/me" component={PageProfile} />
        <Route exact path="/bookmarks" component={PageBookmarks} />
        <Route exact path="/login" component={PageLogin} />

        {/* If path is invalid go to PageHome */}
        <Redirect from="/" to="/" />
      </Switch>
    );
  }
}

export default App;
