import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withAuthorization } from "../components/Session";
import { firestore } from "firebase";

import styles from "./PageNewQuote.module.css";

import AppHeader from "../components/AppHeader/AppHeader";
import ContentBody from "../components/ContentBody/ContentBody";
import NewQuoteSegmentCard from "../components/NewQuoteSegmentCard/NewQuoteSegmentCard";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DoneIcon from "@material-ui/icons/Done";
import AddIcon from "@material-ui/icons/Add";

const EMPTY_SEGMENT = {
  id: 0,
  quote: "",
  cited: ""
};

class PageNewQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      segments: [{ ...EMPTY_SEGMENT }],
      currentSegmentId: 0,
      isPosting: false
    };

    this.addSegment = this.addSegment.bind(this);
    this.removeSegment = this.removeSegment.bind(this);
    this.completeAndUpload = this.completeAndUpload.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  addSegment() {
    var { segments, currentSegmentId } = this.state;

    currentSegmentId++;

    segments.push({ ...EMPTY_SEGMENT, id: currentSegmentId });

    this.setState({ segments, currentSegmentId });
  }

  removeSegment(index) {
    var { segments } = this.state;

    if (segments.length <= 1) {
      //Go back because there would be no segments left
      this.goBack();
    } else {
      //Remove Segment
      segments.splice(index, 1);
      this.setState({ segments });
    }
  }

  updateSegment(index, key, value) {
    var { segments } = this.state;

    //Max Quote Length 300
    //Max Cited Length 25
    if (
      !(
        (key === "quote" && value.length > 300) ||
        (key === "cited" && value.length > 25)
      )
    ) {
      segments[index][key] = value;
    }

    this.setState({ segments });
  }

  completeAndUpload() {
    const { isPosting, segments } = this.state;
    if (isPosting) return;

    this.setState({ isPosting: true });

    const { firebase } = this.props;
    const db = firebase.firestore;

    var conversation = [];

    segments.forEach(s => {
      const totalContentLength = s.quote.length + s.cited.length;

      if (totalContentLength > 2) {
        conversation.push({
          quote: s.quote.trim(),
          cited: s.cited.trim()
        });
      }
    });

    const quoteItem = {
      conversation,
      user: firebase.auth.currentUser.uid,
      date: firestore.FieldValue.serverTimestamp(),
      score: 0
    };

    if (conversation.length >= 1) {
      db.collection("Quotes")
        .add(quoteItem)
        .then(() => {
          this.goBack();
        })
        .catch(error => alert("Es ist ein Fehler aufgetreten: " + error));
    } else {
      this.goBack();
    }
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    const { segments } = this.state;

    const items = segments.map((s, i) => (
      <NewQuoteSegmentCard
        key={s.id}
        quote={s.quote}
        cited={s.cited}
        onQuoteChange={quote => this.updateSegment(i, "quote", quote)}
        onCitedChange={cited => this.updateSegment(i, "cited", cited)}
        onDelete={() => this.removeSegment(i)}
      />
    ));

    return (
      <div>
        <AppHeader
          left={<ArrowBackIcon onClick={this.goBack} />}
          title="Neues Quote"
          right={<DoneIcon onClick={this.completeAndUpload} />}
        />
        <ContentBody>
          {items}
          <AddQuoteSegmentCard onClick={this.addSegment} />
        </ContentBody>
      </div>
    );
  }
}

function AddQuoteSegmentCard(props) {
  return (
    <div className={styles.AddQuoteSegmentCard} onClick={props.onClick}>
      <AddIcon />
    </div>
  );
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(withRouter(PageNewQuote));
