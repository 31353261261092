import React, { Component } from "react";
import styles from "./QuoteCard.module.css";

import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";

import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbDownOutlinedIcon from "@material-ui/icons/ThumbDownOutlined";

import BookmarkIcon from "@material-ui/icons/Bookmark";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";

const COLOR_PALLETT = [
  "#0099ff",
  "#ff6600",
  "#009933",
  "#cc33ff",
  "#333300",
  "#0033cc",
  "#ff33cc",
  "#ffcc00",
  "#cc0066"
];

class QuoteCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLiked: false,
      likeAdder: 0,
      normalizedScore: 0,
      isLikedByMe: false,
      isDislikedByMe: false,
      likingInProgress: false,
      isBookmarked: false
    };

    this.participant_colors = {};
    this.lastColorIndex = 0;

    this.addLike = this.addLike.bind(this);
    this.removeLike = this.removeLike.bind(this);

    this.addBookmark = this.addBookmark.bind(this);
    this.removeBookmark = this.removeBookmark.bind(this);
  }

  getColorFor(name) {
    if (!this.participant_colors[name]) {
      var color = "#000";
      if (this.lastColorIndex < COLOR_PALLETT.length) {
        color = COLOR_PALLETT[this.lastColorIndex];
        this.lastColorIndex++;
      }

      this.participant_colors[name] = color;
    }

    return this.participant_colors[name];
  }

  addLike(isNegative) {
    if (this.state.likingInProgress) return;
    this.setState({ likingInProgress: true });

    const { firebase, quote } = this.props;

    firebase.firestore
      .collection("Quotes/" + quote.id + "/likes/")
      .doc(firebase.auth.currentUser.uid)
      .set({ isNegative })
      .then(() => {
        this.setState({
          isLiked: true,
          isLikedByMe: !isNegative,
          isDislikedByMe: isNegative,
          likeAdder: isNegative ? -1 : 1,
          likingInProgress: false
        });
      })
      .catch(() => {});
  }

  removeLike() {
    if (this.state.likingInProgress) return;
    this.setState({ likingInProgress: true });

    const { firebase, quote } = this.props;

    firebase.firestore
      .collection("Quotes/" + quote.id + "/likes/")
      .doc(firebase.auth.currentUser.uid)
      .delete()
      .then(() => {
        this.setState({
          isLiked: false,
          isLikedByMe: false,
          isDislikedByMe: false,
          likeAdder: 0,
          likingInProgress: false
        });
      })
      .catch(() => {});
  }

  componentDidMount() {
    const { firebase, quote } = this.props;

    const userUid = firebase.auth.getUid();

    let isLikedByMe = false;
    if (quote.likedBy && quote.likedBy.includes(userUid)) {
      isLikedByMe = true;
    }

    let isDislikedByMe = false;
    if (quote.dislikedBy && quote.dislikedBy.includes(userUid)) {
      isDislikedByMe = true;
    }

    let isBookmarked = false;
    if (quote.bookmarkedBy && quote.bookmarkedBy.includes(userUid)) {
      isBookmarked = true;
    }

    this.setState({
      isLikedByMe,
      isDislikedByMe,
      isBookmarked,
      likeAdder: isLikedByMe ? 1 : isDislikedByMe ? -1 : 0,
      normalizedScore: quote.score + (isLikedByMe ? -1 : isDislikedByMe ? 1 : 0)
    });
  }

  addBookmark() {
    if (this.state.likingInProgress) return;
    this.setState({ likingInProgress: true });

    const { firebase, quote } = this.props;

    firebase.firestore
      .collection("Quotes/" + quote.id + "/bookmarks/")
      .doc(firebase.auth.currentUser.uid)
      .set({})
      .then(() => {
        this.setState({
          isBookmarked: true,
          likingInProgress: false
        });
      })
      .catch(() => {});
  }

  removeBookmark() {
    if (this.state.likingInProgress) return;
    this.setState({ likingInProgress: true });

    const { firebase, quote } = this.props;

    firebase.firestore
      .collection("Quotes/" + quote.id + "/bookmarks/")
      .doc(firebase.auth.currentUser.uid)
      .delete()
      .then(() => {
        this.setState({
          isBookmarked: false,
          likingInProgress: false
        });
      })
      .catch(() => {});
  }

  render() {
    const {
      likeAdder,
      isLikedByMe,
      isDislikedByMe,
      normalizedScore,
      isBookmarked
    } = this.state;
    const { quote } = this.props;

    const score = normalizedScore + likeAdder;

    const convItems = quote.conversation.map((conv, index) => (
      <div key={index}>
        <div className={styles.Quote}>„{conv.quote}“</div>
        <div
          className={styles.CitedName}
          style={{
            color: this.getColorFor(conv.cited)
          }}
        >
          - {conv.cited}
        </div>
      </div>
    ));

    return (
      <div className={styles.Card}>
        {convItems}
        <div className={styles.Infobar}>
          <div className={styles.ButtonGroup}>
            <div className={styles.Button}>
              {isBookmarked ? (
                <BookmarkIcon onClick={this.removeBookmark} />
              ) : (
                <BookmarkBorderIcon onClick={this.addBookmark} />
              )}
            </div>
            <div className={styles.Button}>
              {isLikedByMe ? (
                <ThumbUpIcon onClick={this.removeLike} />
              ) : (
                <ThumbUpOutlinedIcon onClick={() => this.addLike(false)} />
              )}
            </div>
            <div className={styles.ButtonDark}>
              {isDislikedByMe ? (
                <ThumbDownIcon onClick={this.removeLike} />
              ) : (
                <ThumbDownOutlinedIcon onClick={() => this.addLike(true)} />
              )}
            </div>
            <div className={styles.LikeCount}>{score.toString()}</div>
          </div>
          <div className={styles.TimeDate}>
            {toDateTime(quote.date.seconds)}
          </div>
        </div>
      </div>
    );
  }
}

export default QuoteCard;

function toDateTime(secs) {
  const date = new Date(secs * 1000);

  var monthNames = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "July",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember"
  ];

  return (
    date.getHours() +
    ":" +
    date.getMinutes() +
    " - " +
    date.getDate() +
    ". " +
    monthNames[date.getMonth()] +
    " " +
    date.getFullYear()
  );
}
