import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

var config = {
  apiKey: "AIzaSyCIAabtmd24IxJPpO5SuzIl6970-4etVU8",
  authDomain: "uelstudios-quotes.firebaseapp.com",
  databaseURL: "https://uelstudios-quotes.firebaseio.com",
  projectId: "uelstudios-quotes",
  storageBucket: "uelstudios-quotes.appspot.com",
  messagingSenderId: "1093050834409"
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.firestore = app.firestore();
  }

  // *** Auth API ***
  doSignIn = () =>
    this.auth.signInWithRedirect(new app.auth.GoogleAuthProvider());

  doSignOut = () => this.auth.signOut();

  // *** Firestore API ***
}

export default Firebase;
