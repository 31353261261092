import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withAuthorization } from "../components/Session";

import AppHeader from "../components/AppHeader/AppHeader";
import ContentBody from "../components/ContentBody/ContentBody";
import ProfileCard from "../components/ProfileCard/ProfileCard";
import BestCard from "../components/BestCard/BestCard";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import BookmarksIcon from "@material-ui/icons/Bookmarks";

class PageProfile extends Component {
  constructor(props) {
    super(props);
    this.state = { notificationsEnabled: true, user: null };

    this.goBack = this.goBack.bind(this);
    this.viewBookmarks = this.viewBookmarks.bind(this);
    this.signOut = this.signOut.bind(this);

    this.toggleNotifications = this.toggleNotifications.bind(this);
  }

  signOut() {
    const { firebase, history } = this.props;

    firebase.doSignOut().then(
      () => {
        history.replace("/login");
      },
      () => {
        alert("Ein Fehler ist aufgetreten.");
      }
    );
  }

  goBack() {
    this.props.history.goBack();
  }

  componentDidMount() {
    const { firebase, history } = this.props;

    if (!firebase.auth.currentUser) {
      history.replace("/");
      return;
    }

    firebase.firestore
      .collection("Users")
      .doc(firebase.auth.currentUser.uid)
      .get()
      .then(result => {
        if (result.exists) {
          if (result.data().notificationsDisabled) {
            this.setState({ notificationsEnabled: false });
          }

          this.setState({ user: result.data() });
        }
      })
      .catch(e => {});
  }

  toggleNotifications() {
    const newState = this.state.notificationsEnabled;
    this.setState({ notificationsEnabled: !newState });

    const { firebase } = this.props;

    firebase.firestore
      .collection("Users")
      .doc(firebase.auth.currentUser.uid)
      .set({ notificationsDisabled: newState }, { merge: true })
      .then(() => {})
      .catch(e => {});
  }

  viewBookmarks() {
    this.props.history.push("/bookmarks");
  }

  render() {
    const { user, notificationsEnabled } = this.state;

    return (
      <div>
        <AppHeader
          left={<ArrowBackIcon onClick={this.goBack} />}
          title="Ich"
          right={<BookmarksIcon onClick={this.viewBookmarks} />}
        />
        <ContentBody>
          <ProfileCard user={user} />
          <BestCard />
          <div
            style={{
              textAlign: "center",
              marginTop: 64,
              marginBottom: 32,
              color: "red"
            }}
            onClick={this.signOut}
          >
            Abmelden
          </div>
        </ContentBody>
      </div>
    );
  }
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(withRouter(PageProfile));

/*
  {notificationsEnabled ? (
    <NotificationsActiveIcon onClick={this.toggleNotifications} />
  ) : (
    <NotificationsOutlinedIcon onClick={this.toggleNotifications} />
  )}
*/
