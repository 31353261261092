import React from "react";

import styles from "./AppHeader.module.css";

function AppHeader(props) {
  return (
    <div className={styles.AppHeader}>
      <div className={styles.Item}>{props.left}</div>
      <div className={styles.Item}>{props.title}</div>
      <div className={styles.Item}>{props.right}</div>
    </div>
  );
}

export default AppHeader;
