import React, { Component } from "react";
import styles from "./BestCard.module.css";
import { withFirebase } from "../Firebase";

//import WhatshotIcon from "@material-ui/icons/Whatshot";

class BestCard extends Component {
  constructor(props) {
    super(props);

    this.state = { users: [] };
  }

  componentDidMount() {
    const { firebase } = this.props;

    firebase.firestore
      .collection("Users")
      .orderBy("score", "desc")
      .limit(10)
      .get()
      .then(docs => {
        const users = [];

        docs.forEach(d => {
          users.push({
            uid: d.id,
            displayName: d.data().displayName,
            score: d.data().score
          });
        });

        this.setState({ users });
      })
      .catch(e => {});
  }

  render() {
    const { users } = this.state;

    const items = users.map((u, index) => (
      <div className={styles.Item} key={u.uid}>
        <div className={styles.ItemPlace}>#{index + 1}</div>
        <div className={styles.ItemName}>
          {u.displayName || "Name unbekannt"}
        </div>
        <div className={styles.ItemScore}>{u.score} Punkte</div>
      </div>
    ));

    return (
      <div className={styles.Card}>
        <div className={styles.Title}>Bestenliste</div>
        {items}
      </div>
    );
  }
}

export default withFirebase(BestCard);
