import React, { Component } from "react";
import { withAuthentication } from "../Session";

import styles from "./ProfileCard.module.css";

class ProfileCard extends Component {
  constructor(props) {
    super(props);
    this.state = { score: "..." };
  }

  render() {
    const { auth } = this.props.firebase;
    const authUser = auth.currentUser;

    if (!authUser || !this.props.user) return null;

    const { score } = this.props.user;

    return (
      <div className={styles.Card}>
        <div>
          <img className={styles.Picture} alt="" src={authUser.photoURL} />
          <div className={styles.Name}>{authUser.displayName}</div>
          <div className={styles.Likes}>{score} Punkte</div>
        </div>
      </div>
    );
  }
}

export default withAuthentication(ProfileCard);
