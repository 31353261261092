import React, { Component } from "react";
import { withFirebase } from "../components/Firebase";
import { withAuthentication } from "../components/Session";

import styles from "./PageLogin.module.css";

class PageLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.signIn = this.signIn.bind(this);
  }

  signIn() {
    const { firebase, history } = this.props;

    firebase
      .doSignIn()
      .then(result => {
        if (result.user) {
          history.replace("/");
        } else {
          alert("Die Anmeldung ist fehlgeschlagen.");
        }
      })
      .catch(error => {
        alert("Es ist folgender Fehler aufgetreten: " + error.message);
      });
  }

  componentDidUpdate() {
    if (this.props.firebase.auth.currentUser) {
      this.props.history.replace("/");
    }
  }

  render() {
    return (
      <div>
        <div className={styles.Background}>
          <img
            alt=""
            src="https://media2.giphy.com/media/3o7qE2VAxuXWeyvJIY/giphy.gif"
          />
        </div>
        <div className={styles.Body}>
          <div className={styles.Sector}>
            <div className={styles.Logo}>
              <div>Quotes</div>
              <div>für die Djungs</div>
            </div>
          </div>
          <div className={styles.Sector}>
            <button className={styles.ButtonSignIn} onClick={this.signIn}>
              Mit Google anmelden
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withFirebase(withAuthentication(PageLogin));
