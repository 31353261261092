import React, { Component } from "react";

import styles from "./SortingCard.module.css";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

class SortingCard extends Component {
  constructor(props) {
    super(props);
    this.state = { isExpanded: false };

    this.toggleExpand = this.toggleExpand.bind(this);
  }

  toggleExpand() {
    this.setState({ isExpanded: !this.state.isExpanded });
  }

  setSort(sort) {
    this.props.setSort(sort);

    this.setState({ isExpanded: false });
  }

  render() {
    const { sorting } = this.props;
    const { isExpanded } = this.state;

    return (
      <div className={isExpanded ? styles.CardExpanded : styles.Card}>
        <div className={styles.Head} onClick={this.toggleExpand}>
          <div className={styles.CurrentSort}>
            {{ top: "Beliebteste zuerst", new: "Neuste zuerst" }[sorting]}
          </div>
          <div className={styles.ExpandButton}>
            <ArrowDropDownIcon />
          </div>
        </div>
        <div className={styles.Body}>
          <div className={styles.SortItem} onClick={() => this.setSort("new")}>
            Neuste zuerst
          </div>
          <div className={styles.SortItemDivider} />
          <div className={styles.SortItem} onClick={() => this.setSort("top")}>
            Beliebteste zuerst
          </div>
        </div>
      </div>
    );
  }
}

export default SortingCard;
