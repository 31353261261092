import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withAuthorization } from "../components/Session";

import AppHeader from "../components/AppHeader/AppHeader";
import ContentBody from "../components/ContentBody/ContentBody";
import QuoteCard from "../components/QuoteCard/QuoteCard";

import PersonIcon from "@material-ui/icons/Person";
import AddIcon from "@material-ui/icons/Add";
import NextPageCard from "../components/NextPageCard/NextPageCard";
import SortingCard from "../components/SortingCard/SortingCard";

const SORT_MODES = {
  new: { field: "date", sort: "desc" },
  top: { field: "score", sort: "desc" }
};

class PageHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quotes: [],
      currentNewestDoc: undefined,
      isLoadingNextPage: true,
      sorting: "new"
    };

    this.addNewQuote = this.addNewQuote.bind(this);
    this.viewMyProfile = this.viewMyProfile.bind(this);

    this.nextPage = this.nextPage.bind(this);
  }

  addNewQuote() {
    this.props.history.push("/new");
  }

  viewMyProfile() {
    this.props.history.push("/me");
  }

  nextPage() {
    this.setState({ isLoadingNextPage: true });

    const { currentNewestDoc, sorting } = this.state;
    if (!currentNewestDoc) return;
    const { firebase } = this.props;
    const db = firebase.firestore;

    const sortMode = SORT_MODES[sorting];

    var quotesRef = db.collection("Quotes");
    quotesRef
      .orderBy(sortMode.field, sortMode.sort)
      .limit(25)
      .startAfter(currentNewestDoc)
      .get()
      .then(
        snapshot => {
          var { quotes } = this.state;

          var lastDoc = undefined;

          snapshot.forEach(doc => {
            quotes.push({ id: doc.id, ...doc.data() });
            lastDoc = doc;
          });

          this.setState({
            isLoadingNextPage: lastDoc === undefined,
            quotes,
            currentNewestDoc: lastDoc
          });
        },
        error => {
          alert(error);
          this.setState({ isLoadingNextPage: false });
        }
      );
  }

  componentDidMount() {
    const { sorting } = this.state;
    const { firebase } = this.props;
    const db = firebase.firestore;

    var quotesRef = db.collection("Quotes");

    const sortMode = SORT_MODES[sorting];

    quotesRef
      .orderBy(sortMode.field, sortMode.sort)
      .limit(25)
      .get()
      .then(
        snapshot => {
          var { quotes } = this.state;

          quotes = [];

          var lastDoc = undefined;

          snapshot.forEach(doc => {
            quotes.push({ id: doc.id, ...doc.data() });
            lastDoc = doc;
          });

          this.setState({
            isLoadingNextPage: false,
            quotes,
            currentNewestDoc: lastDoc
          });
        },
        error => {
          alert(error);
          this.setState({ isLoadingNextPage: false });
        }
      );
  }

  render() {
    const { quotes, isLoadingNextPage, sorting } = this.state;

    const items = quotes.map(q => (
      <QuoteCard key={q.id} quote={q} firebase={this.props.firebase} />
    ));

    return (
      <div>
        <AppHeader
          left={<PersonIcon onClick={this.viewMyProfile} />}
          title="Quotes"
          right={<AddIcon onClick={this.addNewQuote} />}
        />
        <ContentBody offset={40}>
          <SortingCard
            sorting={sorting}
            setSort={sort => {
              this.setState({
                sorting: sort,
                quotes: [],
                currentNewestDoc: undefined,
                isLoadingNextPage: true
              });

              setTimeout(() => this.componentDidMount(), 100);
            }}
          />
          {items}
          {!isLoadingNextPage && <NextPageCard onClick={this.nextPage} />}
        </ContentBody>
      </div>
    );
  }
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(withRouter(PageHome));
