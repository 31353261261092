import React, { Component } from "react";

import styles from "./ContentBody.module.css";

class ContentBody extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.refBody = React.createRef();
  }

  componentDidUpdate() {
    const { offset } = this.props;
    if (this.didScroll || !offset) return;

    this.didScroll = true;
    this.refBody.current.scrollTo(0, offset);
  }

  render() {
    const { children } = this.props;

    return (
      <div className={styles.ContentBody} ref={this.refBody}>
        {children}
      </div>
    );
  }
}

export default ContentBody;
