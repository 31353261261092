import React, { Component } from "react";

import styles from "./NewQuoteSegmentCard.module.css";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

class NewQuoteSegmentCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.onQuoteChange = this.onQuoteChange.bind(this);
    this.onCitedChange = this.onCitedChange.bind(this);
  }

  onQuoteChange(event) {
    this.props.onQuoteChange(this.getTextFromEvent(event));
  }

  onCitedChange(event) {
    this.props.onCitedChange(this.getTextFromEvent(event));
  }

  getTextFromEvent(event) {
    return event.target.value;
  }

  render() {
    const { quote, cited, onDelete } = this.props;

    return (
      <div className={styles.Card}>
        <textarea
          value={quote}
          className={styles.Quote}
          placeholder="Zitat..."
          onChange={this.onQuoteChange}
        />
        <div className={styles.BottomLine}>
          <DeleteForeverIcon onClick={onDelete} />
          <span>
            <input
              value={cited}
              className={styles.Cited}
              placeholder="Name des zitierten"
              onChange={this.onCitedChange}
            />
          </span>
        </div>
      </div>
    );
  }
}

export default NewQuoteSegmentCard;
